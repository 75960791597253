import React from 'react'
import { Button } from '../../../../../../components';
import fileDownloadStyles from "./FIleDownloadStyles.module.css";
const fds = fileDownloadStyles;
interface Idownload {
  section_key?: string | number;
  close: () => void;
  project_key?: string | number
  getfunction?: () => void;
  url?: any
}
function FileDownloader(props: Idownload) {
  const handleDownload = async (imageurl: string, imageName: string) => {
    try {
      const response = await fetch(imageurl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = imageName;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  }
  return (
    <div className={fds.main_con}><div className={fds.text}>
      Are you sure You want to Download the file
    </div>
      <div className={fds.button}>
        <Button onClick={(() => {
          handleDownload(props?.url?.downloadUrl, props?.url?.name)
        })} color="color2">Yes</Button><Button onClick={props.close} color="color3">No</Button>
      </div>
    </div>
  )
}

export default FileDownloader
import React, { useState } from "react";
import EstimationStyles from "./estimationStyles.module.css";
import Folder from "../../../../assets/Folder.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalComponent } from "../../../../components";
import NewSection from "../UploadSection/UploadSection";

const es = EstimationStyles;

const Estimation = (props: any) => {
  const location = useLocation();
  const Navigate = useNavigate();
  const { id, key } = location.state || {};
  const [showModal, setShowModal] = useState<boolean>(false);
  //Here the section key is passed as props through navigation, so that you access the section in the fileUpload component.

  const handleClick = (section_key: any) => {
    Navigate(`section`, { state: { section_key } });
  };

  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <div>
      <div className={es.create_sec}>
        <div className={es.create_text} onClick={() => setShowModal(true)}>
          New Section
        </div>
      </div>
      <div className={es.card}>
        {props.estimation_data?.map((item: any) => {
          console.log(item?.section_name);
          console.log(item?.section_key);
          return (
            <p
              className={es.foldername}
              onClick={() => {
                handleClick(item?.section_key);
              }}
            >
              <span>
                <img src={Folder} alt="" />
              </span>
              {item?.section_name}
            </p>
          );
        })}
      </div>
      <ModalComponent
        Component={() => (
          <NewSection
            design_template="estimation"
            close={handleClose}
            project_key={key}
            getfunction={props?.getfunction}
          />
        )}
        isOpen={showModal}
        onClose={handleClose}
      />
    </div>
  );
};

export default Estimation;

import React, { useState } from "react";
import TwoDStyles from "./twoDStyles.module.css";
import Folder from "../../../../assets/Folder.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalComponent } from "../../../../components";
import NewSection from "../UploadSection/UploadSection";

const tds = TwoDStyles;

const Two_D = (props: any) => {
  const location = useLocation();
  const { id, key } = location.state || {};
  const Navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);

  //Here the section key is passed as props through navigation, so that you access the section in the fileUpload component.

  const handleClick = (section_key: any) => {
    Navigate(`section`, {
      state: { section_key: section_key, project_key: key },
    });
  };
  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <>
      <div>
        <div className={tds.create_sec}>
          <div className={tds.create_text} onClick={() => setShowModal(true)}>
            New Section
          </div>
        </div>
        <div className={tds.card}>
          {props.Two_dDesign?.map((two_d: any) => {
            console.log(two_d?.section_name);
            return (
              <p
                className={tds.foldername}
                onClick={() => handleClick(two_d?.section_key)}
              >
                <span>
                  <img src={Folder} alt="" />
                </span>
                {two_d?.section_name}
              </p>
            );
          })}
        </div>
        <ModalComponent
          Component={() => (
            <NewSection
              design_template="2d"
              close={handleClose}
              project_key={key}
              getfunction={props?.getfunction}
            />
          )}
          isOpen={showModal}
          onClose={handleClose}
        />
      </div>
    </>
  );
};

export default Two_D;

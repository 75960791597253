import React, { useEffect, useState } from "react";
import signupStyles from "./signupStyles.module.css";
import { Button } from "../../../components";
import { useNavigate } from "react-router";
import { CreateSignUp } from "../../../api/SignUp/Post/CreateSignUp.api";
import {
  getCitiesByStateAndCountry,
  getCountries,
  getStatesByCountry,
} from "../../utils/Conutry";
import { GenerateUniqueKey } from "../../Jobs/components/UploadSection/UploadSection";
import { uploadFiles } from "../../../api/FileUpload/fileupload";
import apis from "../../../api";
const ss = signupStyles;
const MAX_FILE_SIZE_IN_MB = 10;
const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MB * 1048576;
interface FileTypes {
  resume: any;
  workFile: any;
}
function Signup() {
  const [countries, setCountries] = useState<any>();
  const [states, setStates] = useState<any>();
  const initialvalues = [
    {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      addressLine1: "",
      addressLine2: "",
      zipcode: "",
      companyName: "",
      country: "",
      state: "",
      district: "",
      experience: "",
      resumeName: "",
      resumeType: "",
      resumeLocation: "",
      resume_extension: "",
      workfileName: "",
      workfileType: "",
      workfileLocation: "",
      workfileUniqueKey: "",
      workfile_extension: "",
      resumeUniqueKey: "",
    },
  ];
  // const [values, setValues] = useState<any>();
  const [cities, setCities] = useState<any>();
  const [values, setValues] = useState<any>({});
  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [show, setShow] = useState(false);
  const [addressLine1Error, setAddressLine1Error] = useState("");
  const [addressLine2Error, setAddressLine2Error] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [experienceError, setExperienceError] = useState("");
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const [resumefiles, setResumeFiles] = useState<any>(undefined);
  const [workfiles, setWorkFiles] = useState<any>(undefined);
  const [filesError, setFilesError] = useState<boolean>();
  const s3BucketPath = {
    resume: "Cad-Signup-Resume/",
    workFile: "Cad-Signup-WorkFiles/",
  };
  const [isDisabled, setIsDisabled] = useState(true);
  // const [uniquekey, setUniquekey] = useState(undef
  const navigate = useNavigate();
  const navigateToPreview = () => {
    navigate("/Preview");
  };
  useEffect(() => {
    getCountries().then((e) => setCountries(e));
  }, []);
  const handleCountryChange = (e: any) => {
    const iso2 = e.target.value.split("-")[1];
    console.log(e.target.value);
    // console.log(iso2);
    setValues({ ...values, country: e.target.value });
    getStatesByCountry(iso2).then((e) => {
      setStates(e);
    });
  };
  const handleStateChange = (e: any) => {
    const iso2 = e.target.value.split("-")[1];
    console.log(e.target.value);
    const countryIso = values.country.split("-")[1];
    console.log(countryIso, iso2);
    setValues({ ...values, state: e.target.value });
    getCitiesByStateAndCountry(countryIso, iso2).then((e) => setCities(e));
  };

  const handleResumeFileChange = (dataFile: any) => {
    const file = dataFile[0];
    setResumeFiles(file);
    console.log("files-------------------", dataFile[0]);
    if (
      file["size"] <= MAX_FILE_SIZE_IN_BYTES &&
      file["type"] == "application/pdf"
    ) {
      setFilesError(false);
      setResumeFiles(file);
      console.log("up");
      console.log("_---------------", dataFile[0]);
      setValues({
        ...values,
        resumeName: file.name.split(".")[0],
        resumeLocation: s3BucketPath.resume,
        resumeType: file.type,
        resumeUniqueKey: GenerateUniqueKey(),
        resume_extension: file.name.split(".").pop(),
      });
      console.log("Resume File _Extension------------", values);
    } else {
      setFilesError(true);
    }
  };

  const handleWorkFileChange = (dataFile: any) => {
    const file = dataFile[0];
    console.log("files-------------------", dataFile[0]);
    if (
      file["size"] <= MAX_FILE_SIZE_IN_BYTES &&
      file["type"] == "application/pdf"
    ) {
      setFilesError(false);
      setWorkFiles(file);
      console.log("up");
      console.log("_---------------", dataFile[0]);
      setValues({
        ...values,
        workfileName: file.name.split(".")[0],
        workfileLocation: s3BucketPath.workFile,
        workfileType: file.type,
        workfileUniqueKey: GenerateUniqueKey(),
        workfile_extension: file.name.split(".").pop(),
      });
      console.log("File _Extension------------", values);
      // console.log("_------init value---------",initialValues);
    } else {
      setFilesError(true);
    }
  };
  const handleNavigate = () => {
    navigate(`/Preview`, { state: { values } });
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const tempValues = { ...values, [name]: value };
    setValues(tempValues);
    console.log(tempValues);

    // console.log(backendData);
    if (e.target.name == "email") {
      if (!tempValues?.email) {
        setEmailError("Email is Required");
      } else if (!emailRegex.test(tempValues?.email)) {
        setEmailError("Please Enter a Valid Email");
      } else {
        setEmailError("");
      }
    }
    if (e.target.name == "firstName") {
      if (!tempValues?.firstName) {
        setFirstNameError("First Name is Required");
      } else if (tempValues?.firstName.length < 2) {
        setFirstNameError("Minimum 3 Characters Required");
      } else {
        setFirstNameError("");
      }
    }
    if (e.target.name == "phoneNumber") {
      if (!tempValues?.phoneNumber) {
        setPhoneError("Phone Number is Required");
      } else if (tempValues?.phoneNumber.length != 10) {
        setPhoneError("Minimum 10 Numbers Required");
      } else {
        setPhoneError("");
      }
    }

    if (e.target.name == "addressLine1") {
      if (!tempValues?.addressLine1) {
        setAddressLine1Error("Please Enter Address");
      } else if (tempValues?.addressLine1.length < 3) {
        setAddressLine1Error("Minimum 3 Characters Required");
      } else {
        setAddressLine1Error("");
      }
    }

    if (e.target.name == "addressLine2") {
      if (!tempValues?.addressLine2) {
        setAddressLine2Error("Please Enter Address");
      } else if (tempValues?.addressLine2.length < 3) {
        setAddressLine2Error("Minimum 3 Characters Required");
      } else {
        setAddressLine2Error("");
      }
    }

    if (e.target.name == "companyname") {
      if (!tempValues?.companyName) {
        setCompanyNameError("Please Enter Company Name");
      } else if (tempValues?.companyName.length < 3) {
        setCompanyNameError("Minimum 3 Characters Required");
      } else {
        setCompanyNameError("");
      }
    }
  };
  const handleSubmit = async () => {
    if (!values?.email) {
      setEmailError("Email is Required");
    } else if (!emailRegex.test(values?.email)) {
      setEmailError("Please Enter a Valid Email");
    } else {
      setEmailError("");
    }
    if (!values?.firstName) {
      setFirstNameError("First Name is Required");
    } else if (values?.firstName.length < 2) {
      setFirstNameError("Minimum 3 Characters Required");
    } else {
      setFirstNameError("");
    }
    if (!values?.phoneNumber) {
      setPhoneError("Phone Number is Required");
    } else if (values?.phoneNumber.length != 10) {
      setPhoneError("Minimum 10 Numbers Required");
    } else {
      setPhoneError("");
    }
    if (!values?.addressLine1) {
      setAddressLine1Error("Please Enter Address");
    } else if (values?.addressLine1.length < 3) {
      setAddressLine1Error("Minimum 3 Characters Required");
    } else {
      setAddressLine1Error("");
    }
    if (!values?.addressLine2) {
      setAddressLine2Error("Please Enter Address");
    } else if (values?.addressLine2.length < 3) {
      setAddressLine2Error("Minimum 3 Characters Required");
    } else {
      setAddressLine2Error("");
    }
    if (!values?.companyName) {
      setCompanyNameError("Please Enter Company Name");
    } else if (values?.companyName.length < 3) {
      setCompanyNameError("Minimum 3 Characters Required");
    } else {
      setCompanyNameError("");
    }

    ////////////////////////------------/////////////////
    const tempData = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone_number: values.phoneNumber,
      salutation: "Mr.",
      address1: values.addressLine1,
      address2: values.addressLine2,
      country: values.country,
      state: values.state,
      district: values?.district,
      zipcode: values.zipcode,
      company_name: values.companyName,
      experience: values.experience,
      resume_name: values.resumeName,
      resume_type: values.resumeType,
      resume_location: values.resumeLocation,
      workfile_name: values.workfileName,
      workfile_type: values.workfileType,
      workfile_location: values.workfileLocation,
      workfile_unique_key: values.workfileUniqueKey,
      resume_unique_key: values.resumeUniqueKey,
      application_status: "pending",
      resume_extension: values.resume_extension,
      workfile_extension: values.workfile_extension,
    };

    console.log(tempData);

    // if (!files.resume || !files.workFile) {
    //   setFilesError(true);
    // } else {
    //   console.log("starting");

    if (resumefiles === "") {
      setFilesError(true);
    } else {
      console.log("starting");

      const resumeData = {
        file_name: values.resumeUniqueKey,
        content_type: resumefiles?.type,
        path: s3BucketPath.resume,
        extension: resumefiles?.name.split(".").pop(),
      };

      const workfileData = {
        file_name: values.workfileUniqueKey,
        content_type: workfiles?.type,
        path: s3BucketPath.workFile,
        extension: resumefiles?.name.split(".").pop(),
      };

      await uploadFiles(resumefiles, resumeData)
        .then(async () => {
          await uploadFiles(workfiles, workfileData);
        })
        .then(async () => {
          const res = await apis.CreateSignUp(tempData);
          if (res.message == "success") {
            //  alert("Suucessfully Sumbitted")
            navigate(`/Preview`, {
              state: {
                values: values,
              },
            });
          }
        });
    }
  };

  return (
    <div className={ss.signup_container}>
      <div className={ss.signup_heading}>Application Form</div>
      <div className={ss.signup_subheading}>
        Please provide all the required details to register your business with
        us. We will get back soon with updates upon receiving this form.
      </div>
      <div className={ss.signup_box}>
        <div className={ss.signupform_linetype1}>
          <div>
            <div className={ss.inputfield_title}>First Name</div>
            <input
              name="firstName"
              placeholder="First Name"
              className={ss.signupbox_inputfield_type1}
              onChange={(e) => {
                {
                  handleChange(e);
                }
              }}
              value={values?.firstName}
            />
            <p className={ss.errors}>{firstNameError}</p>
          </div>
          <div>
            <div className={ss.inputfield_title}>Last Name</div>
            <input
              name="lastName"
              placeholder="Last Name"
              className={ss.signupbox_inputfield_type1}
              onChange={(e) => {
                handleChange(e);
              }}
              value={values?.lastName}
            />
          </div>
        </div>
        <div className={ss.signupform_linetype1}>
          <div>
            <div className={ss.inputfield_title}>Email Id</div>
            <input
              name="email"
              placeholder="E-Mail"
              className={ss.signupbox_inputfield_type1}
              onChange={handleChange}
              value={values?.email}
            />
            <p className="signup-auth-error">{emailError}</p>
          </div>
          <div>
            <div className={ss.inputfield_title}>Phone Number</div>
            <input
              name="phoneNumber"
              placeholder="Phone Number"
              className={ss.signupbox_inputfield_type1}
              onChange={(e) => {
                handleChange(e);
              }}
              value={values?.phoneNumber}
            />
          </div>
          <p className="signup-auth-error">{phoneError}</p>
        </div>

        <div className={ss.signupform_linetype1}>
          <div>
            <div className={ss.inputfield_title}>Address Line 1</div>
            <input
              name="addressLine1"
              placeholder="House / Building Number"
              className={ss.signupbox_inputfield_type1}
              onChange={(e) => {
                {
                  handleChange(e);
                }
              }}
              value={values?.addressLine1}
            />
            <p className="signup-auth-error">{addressLine1Error}</p>
          </div>
          <div>
            <div className={ss.inputfield_title}>Address Line 2</div>
            <input
              name="addressLine2"
              placeholder="Street / Colony Name"
              className={ss.signupbox_inputfield_type1}
              onChange={(e) => {
                {
                  handleChange(e);
                }
              }}
              value={values?.addressLine2}
            />
          </div>
        </div>

        <div className={ss.signupform_linetype1}>
          <div className={ss.select_option}>
            <div className={ss.option_title}> Country</div>
            <select
              className={ss.form_control}
              placeholder="Country"
              onChange={handleCountryChange}
            >
              <option>--Select an Option --</option>
              {countries
                ? countries.map((country: any) => (
                    <option
                      key={country.id}
                      value={`${country.name}-${country.iso2}`}
                    >
                      {country.name}
                    </option>
                  ))
                : ""}
            </select>
          </div>
          <div>
            <div className={ss.option_title}> State</div>
            <select
              placeholder="State"
              className={ss.form_control}
              onChange={handleStateChange}
            >
              <option>--Select an Option --</option>
              {states
                ? states.map((state: any) => (
                    <option
                      key={state.id}
                      value={`${state.name}-${state.iso2}`}
                    >
                      {state.name}
                    </option>
                  ))
                : ""}
            </select>
          </div>
          <div>
            <div className={ss.option_title}> District</div>
            <select
              placeholder="City"
              className={ss.form_control}
              onChange={(e) =>
                setValues({
                  ...values,
                  district: e.target.value,
                })
              }
            >
              <option>--Select an Option --</option>
              {cities
                ? cities.map((city: any) => (
                    <option key={city.id} value={`${city.name}-${city.iso2}`}>
                      {city.name}
                    </option>
                  ))
                : ""}
            </select>
          </div>
          <div>
            <div className={ss.option_title}>Zip Code</div>
            <input
              name="zipcode"
              placeholder="Zip code / Postal Code"
              className={ss.signupbox_inputfield_type2}
              onChange={handleChange}
              value={values?.zipcode}
            />
          </div>
        </div>

        <div className={ss.signupform_linetype1}>
          <div>
            <div className={ss.option_title}>Company Name</div>
            <input
              name="companyName"
              placeholder="Company Name"
              className={ss.signupbox_inputfield_type3}
              onChange={(e) => {
                {
                  handleChange(e);
                }
              }}
              value={values?.companyName}
            />
          </div>
        </div>
        <div className={ss.signupform_linetype1}>
          <div>
            <div className={ss.inputfield_title}>Experience</div>
            <select
              name="experience"
              id="experience"
              className={ss.signupbox_select}
              onChange={handleChange}
            >
              <option>Choose an Option</option>
              <option value="Student">Student</option>
              <option value="No Experience">No Experience</option>
              <option value="Less than 1 Year">Less than 1 Year</option>
              <option value="1 to 2 Years">1 to 2 Years</option>
              <option value="2 to 5 Years">2 to 5 Years</option>
              <option value="More than 5+ Years">More than 5+ Years</option>
            </select>
          </div>
        </div>
        <div className={ss.signupform_linetype1}>
          <div>
            <div className={ss.inputfield_title}>Resume / CV (PDF only)</div>
            <input
              className={ss.signupbox_inputfile}
              type="file"
              id="formFile"
              name="resume"
              onChange={(_) => handleResumeFileChange(_.target.files)}
            />
            <p className="signup-auth-error"></p>
          </div>
        </div>
        <div className={ss.signupform_linetype1}>
          <div>
            <div className={ss.inputfield_title}>
              Upload your Works (PDF only)
            </div>
            <input
              className={ss.signupbox_inputfile}
              type="file"
              id="formFile"
              name="workFile"
              onChange={(_) => handleWorkFileChange(_.target.files)}
            />
          </div>
        </div>
        {!values?.firstName ||
        !values?.addressLine1 ||
        !values?.email ||
        !values?.phoneNumber ||
        !values?.zipcode ||
        !values?.workfileUniqueKey ||
        !values?.resumeUniqueKey ? (
          <>
            {" "}
            <Button
              className={ss.button}
              onClick={() => {
                alert("please Fill All the Fields");
              }}
            >
              Next
            </Button>
          </>
        ) : (
          <Button className={ss.button} onClick={handleSubmit}>
            Next
          </Button>
        )}
      </div>
    </div>
  );
}
export default Signup;

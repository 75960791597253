import React, { useState, useEffect, useContext } from "react";
import ChatpageStyles from "./ChatpageStyles.module.css";
// import ChatMessage from "./Messages/Chatmessage";
import send from "../../../../assets/Send.svg";
import apis from "../../../../api";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthContext/AuthContext";
import { Button, FormInput } from "../../../../components";

const cps = ChatpageStyles;
export type MessageType = {
  id: number;
  tag?: string;
  comment: string;
  created_at: string;
  created_by: string;
};
function ChatPageCard() {
  const params = useParams();
  const auth = useContext(AuthContext);
  const [messages, setMessages] = useState<any>([]);
  const [newMessage, setNewMessage] = useState("");
  const handleMessageClick = async () => {
    const data = {
      comment: newMessage,
      tag: "User",
    };
    const res = await apis.CreateChat(
      auth?.user?.attributes?.sub,
      params?.supportId,
      data
    );
    console.log("Data", res);
    setNewMessage("");
    handleChat();
  };
  useEffect(() => {
    handleChat();
  }, []);
  const handleChat = async () => {
    const res = await apis.GetChat(
      auth?.user?.attributes?.sub,
      params?.supportId
    );
    setMessages(res);
    console.log(res);
  };
  console.log(messages[0]?.comments);
  return (
    <>
      <div className={cps.accounts_card}>
        <div className={cps.card_title}>
          &nbsp;You are chatting with our Support Team
        </div>
        <div className={cps.card_content_div}>
          {messages[0]?.comments === null ? (
            <></>
          ) : (
            messages[0]?.comments.map(
              ({ id, tag, comment, created_at, created_by }: MessageType) => (
                <div className={cps.chat_messages}>
                  {" "}
                  {tag === "User" ? (
                    <div className={cps.user_con}>
                      {created_by}
                      <div className={cps.chat_user}>{comment}</div>
                      {created_at.slice(0, 16)}
                    </div>
                  ) : (
                    <div className={cps.admin_con}>
                      <b>Support Team</b>
                      <div className={cps.chat_admin}>{comment}</div>
                      {created_at.slice(0, 16)}
                    </div>
                  )}
                </div>
              )
            )
          )}
        </div>
        <div className={cps.input_container}>
          <div className={cps.input_container1}>
            <FormInput
              type="text"
              placeholder="Type your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              width={"100"}
            />
          </div>
          <div className={cps.input_container2}>
            <Button onClick={handleMessageClick} width="wide" color="color3">
              <img src={send} alt="Send" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatPageCard;

import React, { useEffect, useState } from "react";
import { Button, FormInput, ModalComponent } from "../../../../components";
import { useLocation, useParams } from "react-router-dom";
import FileSelector from "./components/fileSelector/FileSelector";
import apis from "../../../../api";
import file from "../../../../assets/file.png";
import FileDownloader from "./components/FileDownloader/fileDownloader";
import FileUpload from "../FileUploadSection/fileuploadStyles.module.css";

const fu = FileUpload;
function FileUploadSection() {
  const params = useParams();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDownload, setShowDownload] = useState<boolean>(false);
  const [downloadData, setDownloadData] = useState({});
  const [projectFiles, setProjectFiles] = useState([]);
  const location = useLocation();
  console.log(location.state);
  const handleModal = () => {
    setShowModal(true);
  };
  const handelClose = () => {
    setShowModal(false);
    setShowDownload(false);
  };
  const handleAllFiles = async () => {
    const res = await apis.GetAllFiles(
      params?.jobId,
      location.state.project_key,
      location.state.section_key
    );
    console.log(res);
    setProjectFiles(res);
  };
  useEffect(() => {
    handleAllFiles();
  }, []);
  const handleFileDownload = async (data: any) => {
    console.log(data);
    const res = await apis.GetDownloadUrl(data);
    console.log(res);
    const url = {
      ...res,
      name: data?.file_name + "." + data?.extension,
    };
    setDownloadData(url);
  };
  return (
    <div>
      <div>
        <div className={fu.text}> Upload Section Files</div>
        <br></br>
        <hr />
        <div className={fu.button}>
          <Button color="color2" onClick={handleModal}>
            {" "}
            +  Upload File
          </Button>
        </div>
        <div>
          {projectFiles &&
            projectFiles.map((data: any) => (
              <div
                className={fu.card}
                onClick={() => {
                  handleFileDownload({
                    extension: data?.file_extension,
                    file_name: data?.file_key,
                    path: data?.file_path,
                    name: data?.file_name,
                  });
                  setShowDownload(true);
                  console.log(data);
                }}
              >
                <img className={fu.image} src={file} alt="" />
                <p>{`${data.file_name}.${data.file_extension}`}</p>
              </div>
            ))}
        </div>
      </div>
      <ModalComponent
        Component={() => (
          <FileSelector
            close={handelClose}
            project_key={location.state.project_key}
            section_key={location.state.section_key}
            getfunction={handleAllFiles}
          />
        )}
        isOpen={showModal}
        onClose={handelClose}
        title="File Selector"
      />
      <ModalComponent
        Component={() => (
          <FileDownloader close={handelClose} url={downloadData} />
        )}
        isOpen={showDownload}
        onClose={handelClose}
        title="File Downloader"
      />
    </div>
  );
}

export default FileUploadSection;
